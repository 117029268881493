import {Form, Formik} from "formik";
import React, {useState} from "react";
import {Alert} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import { ReactSelectOption } from "../../../models/shared/ReactSelectOption";
import {CarSelectionInfo} from "../CarSelectionInfo";
import {useLocation, useNavigate} from "react-router-dom";
import CarModelSelection from "../../CarModelSelection/CarModelSelection";
import CarHistorySelect from "../../CarHistorySelect/CarHistorySelect";

interface Props {
    isRimDetailPage?: boolean;
    rimId?: string;
    headline?: string;
    isOnSeoPage?: boolean;
}

export interface SelectCarByHsnTsnSearchRequestDTO {
    hsn: string;
    tsn: string;
}

function SelectCarByHsnTsn(props: Props) {
    const [t] = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [showCarList, setShowCarList] = useState(false);
    const [selectedCar, setSelectedCar] = useState<SelectCarByHsnTsnSearchRequestDTO>();

    function changeHsn(e) {
        if (e.target.value.length >= e.target.getAttribute("maxlength")) {
            document.getElementById('tsn')?.focus();
        }
    }

    function selectHsnTsn(search: SelectCarByHsnTsnSearchRequestDTO) {
        setSelectedCar(search);
        setShowCarList(true);
    }

    function goToRimConfigurator(e: ReactSelectOption) {
        const carId = e.value;
        const searchParams = new URLSearchParams(location.search).toString();
        if (props.isRimDetailPage && props.rimId) {
            navigate({
                pathname: '/felgen/details/' + encodeURIComponent(props.rimId) + '/' + encodeURIComponent(carId),
                search: searchParams
            });
        } else {
            navigate({
                pathname: "/felgenkonfigurator/" + encodeURIComponent(carId),
                search: searchParams
            });
        }
    }

    function getRightContent() {
       if (showCarList) {
           return <div className={"bg-milky"}>
               <CarModelSelection hsnTsnRequest={selectedCar}
                                  isRimDetailPage={props.isRimDetailPage}
                                  isManualSelection={false}
                                  rimId={props.rimId} />
           </div>;
        } else {
           return <div id="certificate-image-container">
               <div id="certificate-image">
                   <img src="kfzschein.png" alt="kfzschein.png"/>
                   <div className="marker hsn"/>
                   <div className="marker tsn"/>
                   <div className="description-box hsn">
                       <h1>{t("CAR_SELECTION.BY_HSN_TSN.CERTIFICATE.HSN.TITLE")}</h1>
                       <span
                           className="d-none d-lg-block">{t("CAR_SELECTION.BY_HSN_TSN.CERTIFICATE.HSN.DESCRIPTION")}</span>
                   </div>
                   <div className="description-box tsn">
                       <h1>{t("CAR_SELECTION.BY_HSN_TSN.CERTIFICATE.TSN.TITLE")}</h1>
                       <span
                           className="d-none d-lg-block">{t("CAR_SELECTION.BY_HSN_TSN.CERTIFICATE.TSN.DESCRIPTION")}</span>
                   </div>
               </div>
               <Alert variant="primary">
                   {t("CAR_SELECTION.BY_HSN_TSN.CERTIFICATE.ALERT")}
               </Alert>
           </div>
        }
    }

    return (
        <div id="hsn-tsn-wrapper">
            <div className="row">
                <div className="col-12 col-md-6 col-lg-4 offset-xl-1">
                    <div id="select-hsn-tsn" className="bg-milky mb-3 mb-md-0">
                        <div>
                            <div className={'container'}>
                                <CarHistorySelect optionSelected={goToRimConfigurator}/>
                            </div>
                            <Formik
                                initialValues={{
                                    hsn: '',
                                    tsn: ''
                                }}
                                onSubmit={selectHsnTsn}
                                validationSchema={
                                    yup.object().shape({
                                        hsn: yup.string().required().max(4),
                                        tsn: yup.string().required().max(10)
                                    })
                                }
                            >
                                {({
                                      handleSubmit,
                                      handleChange,
                                      handleBlur,
                                      values,
                                      touched,
                                      dirty,
                                      isValid,
                                      errors
                                  }) => {
                                    return (
                                        <Form className="hsn-tsn" onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                                                    <div className="form-group ">
                                                        <label htmlFor="hsn" className="control-label h3 pb-1">
                                                            {t("CAR_SELECTION.BY_HSN_TSN.INPUT.HSN")}
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id="hsn"
                                                            name="hsn"
                                                            placeholder=""
                                                            className="form-control"
                                                            maxLength={4}
                                                            minLength={1}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.hsn}
                                                            onInput={(e) => changeHsn(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 mt-2 mt-lg-0 ">
                                                    <div className="form-group">
                                                        <label htmlFor="tsn" className="control-label h3 pb-1">
                                                            {t("CAR_SELECTION.BY_HSN_TSN.INPUT.TSN")}
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id="tsn"
                                                            name="tsn"
                                                            placeholder=""
                                                            className="form-control"
                                                            maxLength={10}
                                                            minLength={1}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.tsn}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <button
                                                        className="btn w-100 btn-primary"
                                                        type="submit"
                                                        disabled={!(dirty && isValid)}
                                                    >
                                                        {t("CAR_SELECTION.BY_HSN_TSN.BTN.SELECT")}
                                                    </button>
                                                </div>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-8 col-xl-6 ">
                    {getRightContent()}
                </div>
            </div>
        </div>
    )
}

export default SelectCarByHsnTsn;
