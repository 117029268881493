import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {SelectCarByHsnTsnSearchRequestDTO} from "../../CarSelection/SelectCarByHsnTsn/SelectCarByHsnTsn";
import * as yup from "yup";
import {Form, Formik} from "formik";
import CarHistorySelect from "../../CarHistorySelect/CarHistorySelect";
import CarModelSelection from "../../CarModelSelection/CarModelSelection";
import {Alert} from "react-bootstrap";
import {useMediaQuery} from "../../../helper/useMediaQuery";

interface Props {
    isRimDetailPage?: boolean;
    rimId?: string;
    headline?: string;
    isOnSeoPage?: boolean;
    carHistoryEntryChosen?: Function;
}

function HsnTsnSelectionMobile(props: Props) {
    const [t] = useTranslation();
    const [showCarList, setShowCarList] = useState(false);
    const [selectedCar, setSelectedCar] = useState<SelectCarByHsnTsnSearchRequestDTO>();

    function changeHsn(e) {
        if (e.target.value.length >= e.target.getAttribute("maxlength")) {
            document.getElementById('tsn')?.focus();
        }
    }

    function selectHsnTsn(search: SelectCarByHsnTsnSearchRequestDTO) {
        setSelectedCar(search);
        setShowCarList(true);
    }

    if (showCarList) {
        return <div className={"bg-milky"}>
            <CarModelSelection hsnTsnRequest={selectedCar}
                               isRimDetailPage={props.isRimDetailPage}
                               rimId={props.rimId}
                               isManualSelection={false}
                               goBack={() => setShowCarList(false)}/>
        </div>
    }

    return (
        <div className="hsn-tsn-selection-mobile">
            <CarHistorySelect optionSelected={props.carHistoryEntryChosen}/>
            <Formik
                initialValues={{
                    hsn: '',
                    tsn: ''
                }}
                onSubmit={selectHsnTsn}
                validationSchema={
                    yup.object().shape({
                        hsn: yup.string().required().max(4),
                        tsn: yup.string().required().max(10)
                    })
                }
            >
                {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      dirty,
                      isValid,
                      errors
                  }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-xs-12 col-sm-6">
                                    <div className="form-group mb-3">
                                        <label htmlFor="hsn" className="control-label">
                                            {t("CAR_SELECTION.BY_HSN_TSN.INPUT.HSN")}
                                        </label>
                                        <input
                                            type="text"
                                            id="hsn"
                                            name="hsn"
                                            placeholder=""
                                            className="form-control"
                                            maxLength={4}
                                            minLength={1}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.hsn}
                                            onInput={(e) => changeHsn(e)}
                                        />
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <div className="form-group mb-3">
                                        <label htmlFor="tsn" className="control-label">
                                            {t("CAR_SELECTION.BY_HSN_TSN.INPUT.TSN")}
                                        </label>
                                        <input
                                            type="text"
                                            id="tsn"
                                            name="tsn"
                                            placeholder=""
                                            className="form-control"
                                            maxLength={10}
                                            minLength={1}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.tsn}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-12 d-grid">
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        disabled={!(dirty && isValid)}
                                    >
                                        {t("CAR_SELECTION.BY_HSN_TSN.BTN.SELECT")}
                                    </button>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
            <div id="certificate-image-container" className={"mt-3"}>
                <div id="certificate-image">
                    <img style={{width: "300px"}} src="/kfzschein_mobile.png" alt="kfzschein_mobile.png"/>
                </div>
                <Alert className={"mt-2"} variant="primary">
                    {t("CAR_SELECTION.BY_HSN_TSN.CERTIFICATE.ALERT")}
                </Alert>
            </div>
        </div>
    )
}

export default HsnTsnSelectionMobile;
