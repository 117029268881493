import classNames from "classnames";
import { produce } from "immer";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { UrlFilterHelper } from "../../helper/UrlFilterHelper";
import { useMediaQuery } from "../../helper/useMediaQuery";
import { FilterInterface } from "../../models/shared/FilterInterface";
import { SearchSort } from "../../models/shared/SearchSort";
import Input, { InputChanged } from "../Input/Input";
import { InputCheckboxGroup } from "../Input/InputCheckboxGroup";
import { InputHelper } from "../Input/InputHelper";
import { SortOptions } from "../Input/SortDropdown";
import { LoadingAnimation } from "../LoadingAnimation/LoadingAnimation";
import RimSizeSelection from "../RimSizeSelection/RimSizeSelection";
import FilterModal from "./FilterModal";
import {RimSearchActionType} from "../../redux-store/reducers/RimSearchReducer";
import {useDispatch} from "react-redux";

interface FilterBarProps {
    filters: FilterInterface[];
    filterSelectionCallback: (filters: FilterInterface[], disableReload?: boolean) => void;
    sortChanged: (value: string) => void;
    sort: SearchSort;
    isLoading: boolean;
}

export function FilterBar({ filters, filterSelectionCallback, sort, sortChanged, isLoading }: FilterBarProps) {
    const navigate = useNavigate();
    const location = useLocation();
    const [t] = useTranslation();
    const [openedFilter, setOpenedFilter] = useState<string>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [activeFilters, setActiveFilters] = useState<FilterInterface[]>(filters);
    const dispatch = useDispatch();
    useEffect(() => {
        setActiveFilters(filters);
    }, [filters]);

    const filterChanged = (values: string[], changedFilter: FilterInterface, apply?: boolean) => {
        const newFilters = produce(activeFilters, (draft) => {
            draft.forEach((filter) => {
                if (filter.key === changedFilter.key) {
                    filter.selectedValues = values.length === 0 ? undefined : values;
                }
            });
        });

        setActiveFilters(newFilters);
        if (apply) {
            filterSelectionCallback(newFilters, false);
            UrlFilterHelper.pushFiltersToHistory(location, navigate, newFilters);
        }
    };

    function getInput(filter: FilterInterface) {
        switch (filter.type) {
            case "CHECKBOX":
            case "MULTI_SELECT_DROPDOWN": {
                if (filter.key === "SIZE") {
                    return (
                        <RimSizeSelection
                            key={filter.key}
                            values={filter.selectedValues}
                            options={filter.values}
                            objectClasses={"col-1 m-1"}
                            multiSelect={true}
                            onSelect={(values) => filterChanged(values, filter)}
                        />
                    );
                } else if (filter.key !== "COLOR_GROUP") {
                    return <InputCheckboxGroup
                        key={filter.key} filter={filter} onChange={(values) => filterChanged(values, filter)} />;
                }
            }
            default:
                return (
                    <Input
                        key={filter.key}
                        label={t(`FILTER.${filter.key}`)}
                        name={filter.key}
                        type={filter.type}
                        options={filter.values}
                        value={filter.selectedValues}
                        translateValue={filter.translateValue}
                        formatNumber={filter.key === "SIZE"}
                        onChange={(values) => filterChanged(values, filter)}
                        hideLabel={true}
                    />
                );
        }
    }

    function applyFilters() {
        filterSelectionCallback(activeFilters, false);
        UrlFilterHelper.pushFiltersToHistory(location, navigate, activeFilters);
        setOpenedFilter(undefined);
    }

    function removeSelectedValue(filter: FilterInterface, value: string) {
        const newValues = filter.selectedValues.filter((v) => v !== value)
        if(filter.key === 'MIN_QUANTITY' && newValues.length === 0) {
            newValues.push("0")
        }
        filterChanged(
            newValues,
            filter,
            true,
        );
    }

    function clearFilter(filter: FilterInterface) {
        filterChanged([], filter, true);
    }

    const isSmallScreen = useMediaQuery("(max-width: 991.98px)");
    const triggerSortChange: InputChanged = (values) => {
        if (values?.length > 0) {
            sortChanged(values[0]);
        }
        setOpenedFilter(undefined);
    };

    function resetFilter() {
        const filters = produce(activeFilters, (draft) => {
            draft.forEach((filter) => {
                filter.selectedValues = [];
            });
        });
        setActiveFilters(filters);
        filterSelectionCallback(filters, false);
        UrlFilterHelper.pushFiltersToHistory(location, navigate, filters);
        setOpenedFilter(undefined);

    }

    return (
        <div className="filter-bar">
            <LoadingAnimation style={{ height: "90px" }} isLoading={isLoading}>
                <div className="row g-0 mb-3">
                    {!isSmallScreen &&
                        activeFilters?.slice(0, activeFilters?.length > 4 ? 3 : activeFilters.length).map((filter) => (
                            <div className="col dropdown" key={'dropdown_'+filter.key}>
                                <button
                                    className="btn btn-light dropdown-toggle w-100"
                                    onClick={() =>
                                        setOpenedFilter(filter.key === openedFilter ? undefined : filter.key)
                                    }
                                >
                                    {t(`FILTER.${filter.key}`)}
                                </button>
                                <div className={classNames("dropdown-menu p-2", { show: filter.key === openedFilter })}>
                                    {getInput(filter)}
                                    <button className="btn btn-primary w-100 mt-2" onClick={applyFilters}>
                                        {t("FILTER.APPLY_FILTER")}
                                    </button>
                                </div>
                            </div>
                        ))}
                    {(isSmallScreen || activeFilters?.length > 4) && (
                        <div className="col-6 col-lg dropdown">
                            <button
                                className="btn btn-light w-100"
                                onClick={() => {
                                    setShowModal(true);
                                    setOpenedFilter(undefined);
                                }}
                            >
                                {t(`FILTER.${isSmallScreen ? "SHOW_FILTERS" : "MORE_FILTER"}`)}
                            </button>
                        </div>
                    )}
                    <div className="col-6 d-lg-none dropdown sort">
                        <button
                            className="btn btn-light dropdown-toggle w-100"
                            onClick={() => setOpenedFilter("SORT" === openedFilter ? undefined : "SORT")}
                        >
                            {t(`FILTER.SORT`)}
                        </button>
                        <div className={classNames("dropdown-menu p-2", { show: "SORT" === openedFilter })}>
                            <Input
                                name={"sort"}
                                type={"RADIO_BUTTON"}
                                value={sort ? [sort.name + "_" + sort.direction] : []}
                                options={SortOptions.map((s) => s.name + "_" + s.direction)}
                                onChange={triggerSortChange}
                                translateValue={true}
                                translatePrefix={"INPUT.SORT_OPTIONS"}
                            />
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-between">
                    <div>
                    {activeFilters?.map((f) => {
                        if (f.type === "MULTI_SLIDER" && f?.selectedValues?.length === 2) {
                            return (
                                <span className="badge bg-light text-dark fw-normal me-2 mb-2 p-2" key={'selection_'+f.key}>
                                    {InputHelper.filterLabel(f.selectedValues[0], f.key, t)}
                                    {" - "}
                                    {InputHelper.filterLabel(f.selectedValues[1], f.key, t)}
                                    <i className="ms-1 fa fa-times cursor" onClick={() => clearFilter(f)}></i>
                                </span>
                            );
                        } else if (f.key === 'MIN_QUANTITY' && f.selectedValues?.[0] === '0') {
                            return null // skip this WCSB2C-498
                        } else {
                            return f?.selectedValues?.map((value) => (
                                <span
                                    className="badge bg-light text-dark fw-normal me-2 mb-2 p-2"
                                    key={f.key + "_" + value}
                                >
                                    {InputHelper.filterLabel(value, f.key, t)}{" "}
                                    <i
                                        className="ms-1 fa fa-times cursor"
                                        onClick={() => removeSelectedValue(f, value)}
                                    ></i>
                                </span>
                            ));
                        }
                    })}
                    </div>
                </div>
                <div >
                    {activeFilters && [].concat(...activeFilters?.filter(f=> !(f.key === 'MIN_QUANTITY' && f.selectedValues?.[0] === '0')).map(f => f.selectedValues || [])).length > 0 &&
                        <button onClick={()=>resetFilter()} className="ps-0 filter-reset-text btn btn-link">{t("FILTER.RESET_ALL")}</button>
                    }
                </div>
            </LoadingAnimation>
            {showModal && (
                <FilterModal
                    filterSearchState={activeFilters}
                    filterSelectionCallback={filterSelectionCallback}
                    onClose={() => setShowModal(false)}
                />
            )}
        </div>
    );
}
