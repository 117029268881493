// import "babel-polyfill";
import "bootstrap";
import React from "react";
import "react-app-polyfill/stable";
import { CookiesProvider } from "react-cookie";
import { createRoot } from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/lib/integration/react";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import Layout from "./components/layout/Layout";
import UserProvider from "./context/UserProvider";
import { initI18n } from "./i18n";
import createStore from "./redux-store/store";
// import Hotjar from '@hotjar/browser';

require("./styles/style.scss");
initI18n();

declare global {
    interface Window {
        REDUX_DATA: any;
    }

}

const store = createStore(window.REDUX_DATA);
const persistor = persistStore(store, {}, () => {
});


// const hotjarSiteId = 5194374;
// const hotjarVersion = 6;
// try {
//     Hotjar.init(hotjarSiteId, hotjarVersion);
// } catch (e) {
//     console.error("[Hotjar init failed]", e);
// }

const jsx = (
    <PersistGate loading={null} persistor={persistor}>
        <CookiesProvider>
            <ReduxProvider store={store}>
                <UserProvider>
                    <Router>
                        <QueryParamProvider
                            adapter={ReactRouter6Adapter}>
                            <Layout />
                        </QueryParamProvider>
                    </Router>
                </UserProvider>
            </ReduxProvider>
        </CookiesProvider>
    </PersistGate>
);

const app = document.getElementById("app");
const root = createRoot(app!); // createRoot(container!) if you use TypeScript
root.render(jsx, app);
